<template>
  <div >
    <div id="pc">
      <div class="pc-top">
        <div style="line-height: 1.5rem">
          <img src="../../assets/公司简介/022017535953_0公司简介.png" alt="" />
        </div>
      </div>
      <div class="breadmenu">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 0.1rem;" :to="{ path: '/bussintroduction' }">公司简介</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="bussBox">
        <div style="width: 1200px;margin: auto;">
          <div style="text-align: center; margin: 20px 0 20px 0">
            <img src="@/assets/images/qianhe.png" alt="" />
          </div>
          <div style="color: #fff;margin: 10px 0px;" v-for="(item, index) in message" :key="index">
            <!-- 河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦  
          河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦  
          河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来，艰苦奋斗、锐意进取，从初创时期的为农产品打开线上销售通路，扶农助农，到现在聚焦   -->
            <!-- {{ item.highlightContent }} -->
            <div class="message" v-html="item.content"></div>
          </div>
          <div style="width: 100%;background-color: rgb(17, 20, 33);padding-bottom: 30px;height: 4.07rem;">
            <div style="margin: 0px auto;width: 1140px;height: 100%;">
              <iframe data-v-cb5a43d0=""
                src="//player.bilibili.com/player.html?aid=482120406&amp;bvid=BV1HT411U7qk&amp;cid=1012032826&amp;page=1"
                scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="allowfullscreen"
                style="width: 1140px; height: 100%;"></iframe>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div id="mobiel">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/公司简介/公司简介.png" alt="" style="width: 4rem" />
        </div>
      </div>
      <div style="background-color: #111421">
        <div style="width: 80%; margin: auto; padding-top: 30px">
          <div style="text-align: center; margin-bottom: 25px">
            <img src="../../assets/公司简介/022017572014_0组 5 拷贝 2.png" alt="" />
          </div>
          <div style="color: #d3bc93; font-size: 16px; margin-bottom: 15px" v-for="(item, index) in message" :key="index">
            <!-- 河北千和电子商务有限公司自成立以来,艰苦奋斗、锐意进取,从初创时期的为农产品打开线上销售通路,扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来,艰苦奋斗、锐意进取,从初创时期的为农产品打开线上销售通路,扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来,艰苦奋斗、锐意进取,从初创时期的为农产品打开线上销售通路,扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来,艰苦奋斗、锐意进取,从初创时期的为农产品打开线上销售通路,扶农助农，到现在聚焦
          河北千和电子商务有限公司自成立以来,艰苦奋斗、锐意进取,从初创时期的为农产品打开线上销售通路,扶农助农，到现在聚焦 -->
            <!-- {{ item.highlightContent }} -->
            <div v-html="item.content"></div>
          </div>
        </div>
        <div style="width: 100%; height: 200px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
import { postAction } from "@/api/manage";
// import img0 from "../../assets/pc服务案例/图层 14.png";
export default {
  data() {
    return {
      message: [],
    };
  },
  created() { },
  activated() { },
  methods: {
    getmobiellist() {
      postAction(
        "/api/article/paginate",
        {
          categoryId: 2,
          pageNumber: 1,
          pageSize: 1,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        // console.log(res.page.list.length,'changdu')
        // this.mototal +=res.page.list.length
        // this.mototals=res.page.totalRow
        //  console.log(res,'返回')
        this.message = res.page.list;
        // res.page.list.forEach(item => {
        //   this.moprogrem.push(item)
        // });

        //this.totalpage =res.page.totalPage
      });
    },
  },
  mounted() {
    this.getmobiellist();
  },
  destroyed() { },
};
</script>
<style lang="less" scoped>
#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}

.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #d9bb95;
}

/deep/.el-breadcrumb__inner.is-link {
  color: #d9bb95;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  background-color: #111421;
  height: 0.35rem;
  padding-left: 2.8rem;
  color: #fff;
}

.pc-top {
  background-image: url(../../assets/招贤纳士/图层\ 14.png);
  width: 100%;
  height: 1.5rem;
  text-align: center;
}

.message {
  div {
    background-color: rgb(201, 38, 38);
  }

  background-color: #111421;

  /deep/ div {
    background-color: #edeff8;

    /deep/ span {
      color: #fff;
    }
  }
}

.bussBox {
  background-image: url('~@/assets/images/back.png');
  background-position: 50% center;
  border-top: 0.01333rem solid rgb(211, 188, 147);
  height: 6.25rem;
}

// /deep/ .message >> div {
//   color: aqua;
// }
</style>
    
<style></style>